<template>
  <router-link
      class="flex items-center mt-4 py-2 px-6 border-l-4"
      :class="[$route.name === routeName ? activeClass : inactiveClass]"
      :to="route"
      v-if="typeof route !== 'undefined'">
    <i :class="icon"></i>
    <span class="mx-4">
      {{ label }}
    </span>
  </router-link>
  <a
      class="flex items-center mt-4 py-2 px-6 border-l-4 cursor-pointer"
      :class="inactiveClass"
      @click="action()"
      v-if="typeof action !== 'undefined'">
    <i :class="icon"></i>
    <span class="mx-4">
      {{ label }}
    </span>
  </a>
</template>

<script>
export default {
  props: {
    icon: String,
    route: String,
    routeName: String,
    label: String,
    action: Function
  },
  data() {
    return {
      activeClass: "bg-gray-900 bg-opacity-75 text-gray-100 border-gray-100",
      inactiveClass: "border-gray-500 text-gray-900 hover:bg-gray-600 hover:bg-opacity-50 hover:text-gray-100"
    }
  }
};
</script>
